<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import service from '../api/portStuffingTerminalService'
import portStuffingTerminalCostService from '../api/portStuffingTerminalCostService'
import portStuffingTerminalAdditionalInformationService from '../api/portStuffingTerminalAdditionalInformationService'
import portStuffingTerminalInformationService from '../api/portStuffingTerminalInformationService'
import portStuffingTerminalAvailableCapacityService from '../api/portStuffingTerminalAvailableCapacityService'
import portStuffingTerminalTakeOrPayCostService from '../api/portStuffingTerminalTakeOrPayCostService'
import portStuffingTerminalVolumeTakeOrPayService from '../api/portStuffingTerminalVolumeTakeOrPayService'
import portStuffingTerminalFreeTimeLogisticPortalService from '../api/portStuffingTerminalFreeTimeLogisticPortalService'

import { CargillCrudMetaView, helpers } from '@cargill/shared'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {},
      createValidationSchema: Function
    }
  },
  methods: {
    async getPortStuffingTerminalInformationMeta() {
      const portStuffingTerminalInformationMeta =
        await portStuffingTerminalInformationService.getMetaWithValidation(this)
      helpers.processMetaDetails(
        portStuffingTerminalInformationMeta,
        'portStuffingTerminalInformation',
        'portStuffingTerminalId',
        'portStuffingTerminal',
        portStuffingTerminalInformationService
      )
      return portStuffingTerminalInformationMeta
    },
    async getPortStuffingTerminalCostMeta() {
      const portStuffingTerminalCostMeta =
        await portStuffingTerminalCostService.getMetaWithValidation(this)
      helpers.processMetaDetails(
        portStuffingTerminalCostMeta,
        'portStuffingTerminalCost',
        'portStuffingTerminalId',
        'portStuffingTerminal',
        portStuffingTerminalCostService
      )
      return portStuffingTerminalCostMeta
    },
    async getPortStuffingTerminalAdditionalInformationMeta() {
      const portStuffingTerminalAdditionalInformationMeta =
        await portStuffingTerminalAdditionalInformationService.getMeta()
      helpers.processMetaDetails(
        portStuffingTerminalAdditionalInformationMeta,
        'portStuffingTerminalAdditionalInformation',
        'portStuffingTerminalId',
        'portStuffingTerminal',
        portStuffingTerminalAdditionalInformationService
      )
      return portStuffingTerminalAdditionalInformationMeta
    },
    async getPortStuffingTerminalVolumeTakeOrPayMeta() {
      const portStuffingTerminalVolumeTakeOrPayMeta =
        await portStuffingTerminalVolumeTakeOrPayService.getMetaWithValidation()
      helpers.processMetaDetails(
        portStuffingTerminalVolumeTakeOrPayMeta,
        'portStuffingTerminalVolumeTakeOrPay',
        'portStuffingTerminalId',
        'portStuffingTerminal',
        portStuffingTerminalVolumeTakeOrPayService
      )
      return portStuffingTerminalVolumeTakeOrPayMeta
    },
    async getPortStuffingTerminalTakeOrPayCostMeta() {
      const portStuffingTerminalTakeOrPayCostMeta =
        await portStuffingTerminalTakeOrPayCostService.getMetaWithValidation()
      helpers.processMetaDetails(
        portStuffingTerminalTakeOrPayCostMeta,
        'portStuffingTerminalTakeOrPayCost',
        'portStuffingTerminalId',
        'portStuffingTerminal',
        portStuffingTerminalTakeOrPayCostService
      )
      return portStuffingTerminalTakeOrPayCostMeta
    },
    async getPortStuffingTerminalAvailableCapacityMeta() {
      const portStuffingTerminalAvailableCapacityMeta =
        await portStuffingTerminalAvailableCapacityService.getMetaWithValidation()
      helpers.processMetaDetails(
        portStuffingTerminalAvailableCapacityMeta,
        'portStuffingTerminalAvailableCapacity',
        'portStuffingTerminalId',
        'portStuffingTerminal',
        portStuffingTerminalAvailableCapacityService
      )
      return portStuffingTerminalAvailableCapacityMeta
    },
    async getPortStuffingTerminalFreeTimeLogisticPortalMeta() {
      const portStuffingTerminalFreeTimeLogisticPortalMeta =
        await portStuffingTerminalFreeTimeLogisticPortalService.getMeta()
      helpers.processMetaDetails(
        portStuffingTerminalFreeTimeLogisticPortalMeta,
        'portStuffingTerminalFreeTimeLogisticPortal',
        'portStuffingTerminalId',
        'portStuffingTerminal',
        portStuffingTerminalFreeTimeLogisticPortalService
      )
      return portStuffingTerminalFreeTimeLogisticPortalMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getPortStuffingTerminalInformationMeta(),
        this.getPortStuffingTerminalAvailableCapacityMeta(),
        this.getPortStuffingTerminalCostMeta(),
        this.getPortStuffingTerminalAdditionalInformationMeta(),
        this.getPortStuffingTerminalVolumeTakeOrPayMeta(),
        this.getPortStuffingTerminalTakeOrPayCostMeta(),
        this.getPortStuffingTerminalFreeTimeLogisticPortalMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(this),
        this.getMetaDetails()
      ])

      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta)
      this.metadata = meta
    })
  }
}
</script>
