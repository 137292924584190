import {
  api,
  createCrudService,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/portStuffingTerminal', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  helpers.updateOptionsRefs(
    ctx.terminalCenterOptions,
    ctx.terminalCentersByTerminalCenterType
  )
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.terminalCenterType.options = ctx.terminalCenterTypeOptions

  const getTerminalCenterOptions = (terminalCenterType) => {
    const terminalCenterTypeId = terminalCenterType?.value
    const options =
      terminalCenterTypeId == null
        ? ctx.terminalCenterOptions
        : ctx.terminalCentersByTerminalCenterType[terminalCenterTypeId]
    return options ?? []
  }

  fieldsById.terminalCenter.options = ({ itemData }) => {
    return getTerminalCenterOptions(itemData?.terminalCenterType?.value)
  }

  fieldsById.terminalCenterType.onValueChange = (changedValue, fields) => {
    helpers.updateOptions(
      component,
      fieldsById.terminalCenter,
      fields.terminalCenter,
      getTerminalCenterOptions(changedValue)
    )
  }
}

export default service
