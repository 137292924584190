import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/portStuffingTerminalAvailableCapacity',
  api
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.portStuffingTerminal.options = ctx.portStuffingTerminalOptions
  fieldsById.stuffingUsedCapacityUnity.options =
    ctx.stuffingUsedCapacityUnityOptions
  fieldsById.stuffingAvailableCapacityUnity.options =
    ctx.stuffingAvailableCapacityUnityOptions
}

export default service
