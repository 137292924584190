import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/portStuffingTerminalVolumeTakeOrPay',
  api
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.portStuffingTerminal.options = ctx.storageTerminalOptions
  fieldsById.volumeTakeOrPayUnity.options = ctx.volumeTakeOrPayUnityOptions
}

export default service
